import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'term-dialog',
	templateUrl: './terms-dialog.component.html',
	styleUrls: ['./terms-dialog.component.scss']
})
export class TermsDialogComponent implements OnInit {

	public isLoading: boolean;

	constructor(public modal: NgbActiveModal) {
	}

	public ngOnInit(): void {
	}

}
