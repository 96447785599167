import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationService, SystemMessages, ToastService, TrainingService } from '@app/service';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { IdPermissions } from '@app/model';

@Component({
	selector: 'evo-switch',
	templateUrl: './evo-switch.component.html',
	styleUrls: ['./evo-switch.component.scss']
})
export class EvoSwitchComponent implements OnInit {

	@Input() isInternal: boolean = false;

	public showOrganizationButton: boolean = false;
	public products: SwitchProducts[] = [];

	@Output() switchOrganizations: EventEmitter<any> = new EventEmitter<any>();
	@Input() public showAdmin: boolean = true;
	@Input() public showAccount: boolean = false;

	public showTraining: boolean = false;
	public loading: boolean = false;

	constructor(public organizationService: OrganizationService,
				public ngxPermissionService: NgxPermissionsService,
				public trainingService: TrainingService,
	            private router: Router,
	            private modalService: NgbModal,
	            private toastService: ToastService,
	            @Inject(DOCUMENT) private document: Document) {
	}

	ngOnInit(): void {
		this.ngxPermissionService.hasPermission([IdPermissions.ALL_PERMISSIONS, IdPermissions.TRAINING_ACCESS]).then(
			data => {
				this.showTraining = data;
			});
	}

	@Input()
	public set switchProductsOrganizations(value: SwitchProductsOrganizations) {
		value.allProducts.forEach(product => {
			let switchProducts = new SwitchProducts();
			switchProducts.alias = product.alias;
			switchProducts.name = product.name;
			switchProducts.accessUrl = product.accessUrl;
			switchProducts.organizations = [];

			this.showOrganizationButton = value.allOrganizations.length > 1;

			value.allOrganizations.forEach((organization: any) => {
				organization.products.forEach((organizationProduct: { alias: string; }) => {
					if(organizationProduct.alias == product.alias) {
						switchProducts.organizations = [ ...switchProducts.organizations, organization ];
					}
				});
			});

			this.products.push(switchProducts);
		});
	}

	public goToTraining(): void {
		if(this.loading) {
			return;
		}

		this.loading = true;
		this.trainingService.getLoginUrl().subscribe(
			data => {
				this.document.location.href = data.url;
			}, error => {
				if(error.status == 400 && error.error.error == SystemMessages.USER_LASTNAME_REQUIRED) {
					this.toastService.showToast('Não foi possível acessar o Training, entre em contato o administrador do seu sistema.')
				} else {
					this.toastService.showDanger(SystemMessages.UNEXPECTED_ERROR);
				}
			}).add( () => {
			this.loading = false;
		});
	}

	public onSwitchOrganizations(): void {
		this.switchOrganizations.emit();
	}
}

export class SwitchProducts {
	public alias: string;
	public name: string;
	public organizations: any[];
	public accessUrl: string;

	public isOpen: boolean = false;
}

export class SwitchProductsOrganizations {
	public allProducts: any[];
	public allOrganizations: any[];
}


