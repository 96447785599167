import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService, UserService } from '@app/service';
import { CommonErrors, SystemErrors, UpdateUser } from '@app/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsDialogComponent } from '../../../shared/terms-dialog/terms-dialog.component';
import { Auth } from '@aws-amplify/auth';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-login-without-email',
	templateUrl: './login-without-email.component.html',
	styleUrls: ['./login-without-email.component.scss']
})
export class LoginWithoutEmailComponent implements OnInit {

	public formGroup: FormGroup = new FormGroup({
		name: new FormControl(null, [Validators.required]),
		lastName: new FormControl(null, [Validators.required]),
		password: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&|<>])[A-Za-z\d@$!%*?#&|<>]{8,}$/)]),
		confirmPassword: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&|<>])[A-Za-z\d@$!%*?#&|<>]{8,}$/)]),
		terms: new FormControl(null, [Validators.required])
	});

	get name() { return this.formGroup.get('name'); }
	get lastName() { return this.formGroup.get('lastName'); }
	get password() { return this.formGroup.get('password'); }
	get confirmPassword() { return this.formGroup.get('confirmPassword'); }
	get terms() { return this.formGroup.get('terms'); }

	public showPassword: boolean = false;
	public showConfirmPassword: boolean = false;

	public passwordButtonPositionClass: string = '';
	public passwordConfirmButtonPositionClass: string = '';

	public registerDone: boolean = false;

	public isLoading: boolean = false;

	@Output()
	public passwordChangeCompleted: EventEmitter<any> = new EventEmitter<any>();

	constructor(public userService: UserService,
	            private toastService: ToastService,
	            public modalService: NgbModal,
	            private router: Router,
	            @Inject(DOCUMENT) private document: Document) {
	}

	ngOnInit(): void {
		this.formGroup?.setValidators([this.validateConfirmPasswordIsEqualToPassword, this.validateTerms]);
	}

	onSubmit() {
		if (this.formGroup.invalid) {
			return;
		}

		this.isLoading = true;

		Auth.completeNewPassword(this.userService.cognitoUser, this.password?.value).then(
			user => {

				this.userService.jwtToken = user.signInUserSession.accessToken.jwtToken;
				localStorage.setItem('jwtToken', this.userService.jwtToken as string);

				this.getCurrentUser();
			}).catch(e => {
				console.error(e);

			});
	}

	private getCurrentUser(): void {
		this.userService.getCurrentUser().subscribe(
			data => {
				this.userService.currentUser = data;

				this.updateUser();
			}, error => {
				console.error(error);
				this.toastService.showDanger(SystemErrors.UNEXPECTED_ERROR);
			}
		)
	}

	private updateUser(): void {
		let updateUser = new UpdateUser();
		updateUser.name = this.name?.value + ' ' + this.lastName?.value;

		this.userService.put(updateUser, this.userService.currentUser.id).subscribe(
			data => {
				this.toastService.showSuccess('Dados atualizados com sucesso.');
				this.document.location.href = environment.apps['quality'];
			}, error => {
				this.toastService.showDanger(SystemErrors.UNEXPECTED_ERROR);
			}
		)
	}

	changeShowPasswordButtonPosition() {
		if (this.password?.untouched) {
			return;
		}
		this.passwordButtonPositionClass = this.password?.invalid ? 'mr-4' : '';
	}

	changeShowConfirmPasswordButtonPosition() {
		if (this.confirmPassword?.untouched) {
			return;
		}
		this.passwordConfirmButtonPositionClass = this.confirmPassword?.invalid ? 'mr-4' : '';
	}

	validateTerms(form: AbstractControl): ValidationErrors | null {
		let error = null;

		if(!form.get('terms')?.value) {
			error = { needAccept: true }
			form.get('terms')?.setErrors({ needAccept: true });
		}

		return error;
	}

	validateConfirmPasswordIsEqualToPassword(form: AbstractControl): ValidationErrors | null {
		const password = form.get('password')?.value;
		const confirmPassword = form.get('confirmPassword')?.value;

		let error;
		if (password === confirmPassword) {
			error = null;
		} else {
			error = {notSame: true};
			form.get('confirmPassword')?.setErrors({notSame: true});
		}
		return error;
	}

	openTerms(event: any): void {
		event.stopPropagation();

		const modalRef = this.modalService.open(TermsDialogComponent, { size: 'xl', centered: true, scrollable: true });

		modalRef.result.then( data => {
			if(data) {
				this.terms?.setValue(true);
			}
		});
	}

	public preventSpace(event: any) {
		if(event.keyCode === 32){
			event.preventDefault();
		}
	}

}
