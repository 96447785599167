import { Component, OnInit } from '@angular/core';
import { OrganizationService, ProductService, UserService } from '@app/service';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Organization } from '@app/model';
import { SwitchProductsOrganizations } from '../evo-switch/evo-switch.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	public organizations: Organization[];
	public switchProductsOrganizations: SwitchProductsOrganizations;

	public finalArray: any[] = [];

	public showAdminBtn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public showSupport: boolean = environment.features.enableSupport;
	public showFaq: boolean = environment.features.enableFaq;

	constructor(public userService: UserService,
				public organizationService: OrganizationService,
				public productService: ProductService,
				public modalService: NgbModal,
	            private router: Router) {
	}

	ngOnInit(): void {
		this.organizationService.findAll().pipe(
			switchMap((value) => {
				this.organizations = value;
				return this.productService.findAll();
			})
		).subscribe(
			data => {
				this.switchProductsOrganizations = new SwitchProductsOrganizations();
				this.switchProductsOrganizations.allProducts = data;
				this.switchProductsOrganizations.allOrganizations = this.organizations;
			});

		this.checkAdminPermission();
	}

	public checkAdminPermission(): void {
		let hasAdminPermission = false;

		if(!this.userService.currentUser.organizations) {
			return;
		}

		this.userService.currentUser.organizations.some(org => {
			this.showAdminBtn.next(org.admin);

			return org.admin;
		});

		if(this.showAdminBtn.value || !this.userService.currentUser.roles || this.userService.currentUser.roles.length == 0) {
			return;
		}

		this.userService.currentUser.roles.some(role => {
			let adminPermission = role.permissions.filter((permission: any) => permission.product == 'ADMIN');

			if(adminPermission.length > 0) {
				hasAdminPermission = true;
				this.showAdminBtn.next(true);
			}

			return hasAdminPermission;
		});
	}

	public openHelpDialog(): void {
		const modalRef = this.modalService.open(HelpDialogComponent, {});

		modalRef.result.then( data => {
			if(data) {
			}
		})
	}

	public logout(): void {
		this.userService.logout();
	}
}
