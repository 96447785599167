import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'evo-inline-edit',
	templateUrl: './inline-edit-input.component.html',
	styleUrls: ['./inline-edit-input.component.scss']
})
export class InlineEditInputComponent {

	@Output() public changeDone: EventEmitter<any> = new EventEmitter<any>();
	@Input() public value: any;

	@Input() public permissions: string[] | string;
	@Input() public isCenter: boolean = false;
	@Input() public placeholder: string = 'sem valor';

	@Input() public blockSpace: boolean = false;

	public tempValue: string;
	public showInput: boolean = false;

	constructor() {
	}

	public onShowInput(): void {
		this.showInput = true;
		this.tempValue = this.value;
	}

	public onChangeCancel(): void {
		this.showInput = false;
	}

	public onChangeDone(): void {
		this.changeDone.emit(this.tempValue);
		this.showInput = false;
	}

	public preventSpace(event: any) {
		if(event.keyCode === 32 && this.blockSpace){
			event.preventDefault();
		}
	}
}
