import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseServiceClass } from './base-service';
import { environment } from '../../environments/environment';
import { OrganizationService } from './organization.service';

@Injectable({
	providedIn: 'root'
})
export class TrainingService extends BaseServiceClass<any> {

	constructor(private http: HttpClient,
	            private organizationService: OrganizationService) {
		super(http, '');

		this.entity_url = environment.qualityApiUrl + '/v1/trainings';
	}

	public getLoginUrl(): Observable<any> {
		let headers = { 'organization' : this.organizationService.currentOrganization.alias }

		return this.http.get(`${this.entity_url}/ead/login/url`, { headers });
	}

}
