import { EvoModel } from './evo-model';

export class Permission extends EvoModel {

	public id: string;
	public code: string;
	public name: string;
	public description: string;
	public product: any;
	public group: PermissionGroup;
	public createdAt: any;
	public updatedAt: any;

	public deserialize(input: any): this {
		return super.deserialize(input);
	}
}

export enum IdPermissions {
	ALL_PERMISSIONS = 'ALL_PERMISSIONS',
	TRAINING_ACCESS = 'TRAINING_ACCESS',
}

export enum PermissionGroup {
	USERS = 'Usuários',
	SEGMENTS = 'Segmentos',
	OPERATIONS = 'Operações',
	CHECKLISTS = 'Check List',
	REPORTS = 'Relatórios',
	INTERACTIONS = 'Interações',
	ORGANIZATIONS = 'Organizações',
	ROLES = 'Perfis',
	FLOWS = 'Fluxos',
	JUSTIFICATIONS = 'Justificativas',
	DASHBOARDS = 'Dashboards',
	TRAINING_PLATAFORM = 'Training',
	TRAINING = 'Treinamentos'
}
