import { EvoModel } from './evo-model';

export class AwsUserInfo extends EvoModel {

	public id: number = 0;
	public username: string = '';

	public attributes: AwsUserInfoAttributes = new AwsUserInfoAttributes();

	public deserialize(input: any): this {
		return super.deserialize(input);
	}

	constructor() {
		super();
	}
}

export class AwsUserInfoAttributes extends EvoModel {
	public name: string = '';
}
