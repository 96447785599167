import { BaseServiceClass } from './base-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { Organization } from '@app/model';

@Injectable({
	providedIn: 'root'
})
export class OrganizationService extends BaseServiceClass<any> {

	public currentOrganization: Organization = new Organization();
	public forceOrganizationSelection: boolean = false;

	constructor(private http: HttpClient, private router: Router, private userService: UserService) {
		super(http, '/api/v1/organizations');
	}

	public find(id: any): Observable<any> {
		return this.http.get(`${this.entity_url}/${id}`);
	}

	public findAll(): Observable<any> {
		return this.http.get(`${this.entity_url}?product=QUALITY`);
	}

}
