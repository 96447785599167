import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	public constructor(private userService: UserService, private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (this.userService.currentUser) {
			return true;
		}

		this.userService.returnUrl = state.url;

		this.router.navigate(['/']);
		return false;
	}
}
