import { BaseServiceClass } from './base-service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class ProductService extends BaseServiceClass<any> {

	constructor(private http: HttpClient, private router: Router, @Inject(DOCUMENT) private document: Document) {
		super(http, '/api/v1/products');
	}
}
