<div class="app-header" *ngIf="userService.jwtUser">

	<div class="logo-wrapper">

		<div class="header-dropdown" ngbDropdown container="body">
			<button class="btn" ngbDropdownToggle>
				<i class="fas fa-th"></i>
			</button>

			<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
				<evo-switch *ngIf="switchProductsOrganizations"
				            [switchProductsOrganizations]="switchProductsOrganizations"
				            [showAdmin]="showAdminBtn.value">
				</evo-switch>
			</div>
		</div>


		<div class="product-logo ID"></div>

		<h5>Conta da Evollo</h5>
	</div>

	<div class="action-wrapper">
		<div class="action-buttons">
			<!-- <button class="btn">
				<i class="fas fa-bell"></i>
			</button>
			<button class="btn">
				<i class="fas fa-question-circle"></i>
			</button> -->
			<button class="btn" (click)="openHelpDialog()" *ngIf="showSupport">
				<img src="/assets/icons/helpdesk.svg" width="18" height="18" style="opacity: 0.7;"/>
			</button>

			<a href="https://evollo.com.br/artigos" target="_blank" class="btn" *ngIf="showFaq">
				<i class="far fa-question-circle"></i>
			</a>

			<!-- <button class="btn" (click)="goToOrganizationList()">
				<i class="fas fa-cog"></i>
			</button> -->
		</div>

		<span class="user-info">
			{{ userService.currentUser.name }}
		</span>

		<div class="header-dropdown" ngbDropdown container="body">
			<avatar-initials [small]="true" [name]="userService.currentUser.name" ngbDropdownToggle></avatar-initials>

			<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
				<a class="dropdown-item pointer" (click)="logout()">
					<img class="mr-2" src="/assets/icons/sign-out.svg" width="18" height="18" style="opacity: 0.8"/>
					Sair
				</a>
			</div>
		</div>
	</div>

</div>
