import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/service';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { LoginService } from 'app/service/login-service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-auth-callback',
	templateUrl: './auth-callback.component.html',
	styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

	constructor(private route: ActivatedRoute, private router: Router, private userService: UserService, private loginService: LoginService) {
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			const code = params['code'];
			const error = params['error_description'];
			const state = params['state'];

			if (error && error.includes("USER_NOT_FOUND")) {
				window.location.href = environment.apps['id'] + '?error=USER_INVALID' + '&returnUrl=' + this.loginService.redirectUrl;
			} else if(code) {
				Auth.currentSession().then(res=>{
					let accessToken = res.getAccessToken()
					let jwt = accessToken.getJwtToken()
					localStorage.setItem('jwtToken', jwt);
					window.location.href =  environment.apps['id']  + '?token=' + jwt + '&returnUrl=' + this.loginService.redirectUrl;
				  });
			}
		});
		
		
	}

}
