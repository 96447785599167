import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import jwt_decode from 'jwt-decode';
import { ToastService, UserService } from '@app/service';
import { SystemErrors } from '@app/model';

@Component({
	selector: 'app-verify-user',
	template: ``
})
export class VerifyUserComponent implements OnInit {

	constructor(private userService: UserService,
				private activatedRoute: ActivatedRoute,
				private router: Router,
	            private toastService: ToastService) {

		const token = this.getDecodedAccessToken(this.activatedRoute.snapshot.queryParams['token']);

		const tokenExpireAt = new Date();
		tokenExpireAt.setTime(token.exp * 1000);

		const now = new Date();

		if(now.getTime() > tokenExpireAt.getTime()) {
			this.toastService.showDanger('Token expirado, crie sua conta novamente.');
			this.router.navigate(['/']);
			return;
		}

		this.validateUser(this.activatedRoute.snapshot.queryParams['token']);
	}

	ngOnInit(): void {

	}

	private validateUser(token: string): void {
		this.userService.verify(token).subscribe(
			data => {
				this.userService.logout();
				this.toastService.showSuccess('Seu endereço de email foi verificado com sucesso!', { className: 'toast-success bottom-left', delayTime: 5000 });
				this.router.navigate(['/']);
			}, error => {
				this.toastService.showDanger(SystemErrors.UNEXPECTED_ERROR);
				this.router.navigate(['/']);
			});
	}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			this.toastService.showDanger('Token inválido.');
		}
	}


}
