import { EvoModel } from './evo-model';

export class Organization extends EvoModel {

	public id: string;
	public code: number;
	public name: string;
	public logoUrl: any;
	public alias: string;
	public status: string;
	public createdAt: any;

	public admin: boolean;

	public products: any[];

	public profiles: any[];

}
