import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Auth } from '@aws-amplify/auth';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { OrganizationService, ToastService, UserService } from '@app/service';
import { IdPermissions, JwtUser, SystemErrors } from '@app/model';
import { environment } from '../../../environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MfaDialogComponent } from "./mfa/mfa-dialog.component";
import { LoginService } from 'app/service/login-service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	public username: string = '';
	public password: string = '';
	public newPassword: string = '';

	public loginForm: FormGroup;
	public passwordRecoveryForm: FormGroup;
	public isSubmittingForm = false;
	public submittedLogin = false;

	public accessToken: string = '';
	public redirectApp: string;
	public redirectUrl: string = '';
	public error: string;

	public showLoginForm: boolean = false;
	public currentState: string = LoginStates.LOGIN;

	public loginStates = LoginStates;

	public showPassword: boolean = false;
	public passwordButtonPositionClass: string = '';

	public enableSignUp: boolean = environment.features.enableSignUp;
	public enableAzureSignUp: boolean = environment.features.enableAzureSignUp;

	public recaptchaId: string = environment.recaptchaId;
	public recaptchaToken: string = '';
	public validRecaptcha: boolean = !environment.features.recaptchaEnabled;

	public recaptchaEnabled = environment.features.recaptchaEnabled;

	public get loginFormCtrl() {
		return this.loginForm.controls;
	}

	constructor(private formBuilder: FormBuilder,
				private activatedRoute: ActivatedRoute,
				private router: Router,
				private toastService: ToastService,
				public userService: UserService,
				public organizationService: OrganizationService,
				public ngxPermissionsService: NgxPermissionsService,
				private modalService: NgbModal,
				private loginService: LoginService,
				@Inject(DOCUMENT) private document: Document) {

		this.loginForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
			recaptchaReactive: [!environment.features.recaptchaEnabled, [Validators.requiredTrue]]
		});
		this.passwordRecoveryForm = this.formBuilder.group({
			username: ['', [Validators.required]],
		});

		this.redirectApp = this.activatedRoute.snapshot.queryParams['redirectApp'] ?? 'id';
		this.redirectUrl = this.activatedRoute.snapshot.queryParams['redirectUrl'] ?? '';
		this.error = this.activatedRoute.snapshot.queryParams['error'] ?? undefined;
		this.loginService.setRedirectApp(this.redirectApp);
		this.loginService.setRedirectUrl(this.redirectUrl);
	}

	ngOnInit(): void {
		this.getCurrentSession();
	}

	public getCurrentSession(): void {
		if(this.error && this.error === 'USER_INVALID'){
			this.showUserOrPasswordInvalid();
		}

		if (!localStorage.getItem('jwtToken')) {
			this.showLoginForm = true;
			return;
		}

		this.userService.jwtToken = localStorage.getItem('jwtToken') ?? '';
		this.userService.refreshToken = localStorage.getItem('refreshToken') ?? '';
		this.userService.deviceKey = localStorage.getItem('deviceKey') ?? '';

		Auth.currentAuthenticatedUser().then(
			cognitoUser => {
				this.userService.cognitoUser = cognitoUser;

				this.userService.updateTokens(cognitoUser);
				this.userService.jwtUser = new JwtUser().deserialize(this.userService.jwtToken);

				this.userService.getCurrentUser().subscribe(
					data => {
						this.userService.currentUser = data;
						if (!this.userService.currentUser.mfaEnabled) {
							this.validateSignIn(cognitoUser);
						} else {
							this.validateCurrentUser();
						}
					}, error => {
						this.showLoginForm = true;
					});
			}, error => {
				this.showLoginForm = true;
			});
	}

	submitLogin() {
		this.submittedLogin = true;

		if (this.loginForm.invalid) {
			return;
		}
		this.isSubmittingForm = true;

		Auth.signIn(this.loginFormCtrl.username.value, this.loginFormCtrl.password.value).then(
			cognitoUser => {
				if (!cognitoUser.signInUserSession && cognitoUser.challengeName == 'SOFTWARE_TOKEN_MFA') {
					this.userService.tempUsername = this.loginFormCtrl.username.value;
					this.userService.cognitoUser = cognitoUser;
					this.openMfaDialog(false, 'APP', cognitoUser.challengeName);
					return;
				}

				this.userService.cognitoUser = cognitoUser;
				this.userService.tempUsername = cognitoUser.username;
				if (cognitoUser.challengeName == 'PASSWORD_VERIFIER' || cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
					this.currentState = LoginStates.CHANGE_PASSWORD;
					return;
				}

				this.userService.updateTokens(cognitoUser);

				this.userService.getCurrentUser().subscribe(
					data => {
						this.userService.currentUser = data;
						this.validateSignIn(cognitoUser);
					}, error => {
						this.toastService.showDanger(SystemErrors.UNEXPECTED_ERROR);
						this.isSubmittingForm = false;
					});
			}
		).catch(error => {
			console.error(error);
			this.showUserOrPasswordInvalid();
			this.isSubmittingForm = false;
		});
	}

	public changeShowPasswordButtonPosition() {
		if (this.loginForm.get('password')?.untouched) return;
		this.passwordButtonPositionClass = this.loginForm.get('password')?.invalid ? 'mr-4' : '';
	}

	public loginWithAzure(): void {
		Auth.federatedSignIn({customProvider: 'microsoft'}).then(
			data => {
				console.log(data);

			},
			error => {
				console.error(error)
			}
		);
	}

	public validateRecaptcha(event: any): void {
		this.recaptchaToken = event;

		this.userService.validateRecaptcha(this.recaptchaToken).subscribe(
			data => {
				this.validRecaptcha = data.success;
				this.loginForm.get('recaptchaReactive')?.setValue(data.success);
			}, error => {
				this.validRecaptcha = false;
				this.loginForm.get('recaptchaReactive')?.setValue(null);
				// this.toastService.showDanger(SystemMessages.INVALID_RECAPTCHA);
			});
	}

	private validateCurrentUser(): void {
		if (this.userService.currentUser.status == 'FORCE_PASSWORD_CHANGE') {
			this.addPermissionsAndRedirect("profile/security");
			return;
		}

		if(this.userService.currentUser.status == 'INACTIVE') {
			this.toastService.showDanger('Usuário inativo. Entre em contato com o suporte.');
			this.userService.logout();
			return;
		}

		if(this.userService.returnUrl == '/profile') {
			this.addPermissionsAndRedirect();
			return;
		}

		if (this.userService.currentUser.organizations.length === 0) {
			this.redirectApp = environment.apps['admin'];
			this.redirectUser();
			return;
		}

		if (localStorage.getItem('logoutRedirect')) {
			let logoutRedirect = localStorage.getItem('logoutRedirect');
			localStorage.removeItem('logoutRedirect');
			this.document.location.href = environment.apps[logoutRedirect as string];
			return;
		}

		if (this.userService.returnUrl == '') {
			if(!this.redirectApp || this.redirectApp == 'id') {
				this.redirectApp = environment.apps['quality'];
			}
			this.redirectUser();
			return;
		}

		this.addPermissionsAndRedirect();
	}

	private addPermissionsAndRedirect(url?: string): void {
		this.addPermissions();
		this.router.navigate([url ? url : this.userService.returnUrl]);
		this.userService.returnUrl = '';
	}

	private openMfaDialog(canRegister: boolean, mfaType: string, challengeName: string | null): void {
		const modalRef = this.modalService.open(MfaDialogComponent, {backdrop: 'static', keyboard: false});
		modalRef.componentInstance.canRegister = canRegister;
		modalRef.componentInstance.mfaType = mfaType;
		modalRef.componentInstance.challengeName = challengeName;

		modalRef.result.then(data => {
			if (data) {
				this.validateCurrentUser();
			}
		});
	}

	private validateSignIn(cognitoUser: any): void {
		this.userService.cognitoUser = cognitoUser;
		if (cognitoUser.attributes) {
			this.userService.tempUsername = cognitoUser.attributes.email
		} else {
			this.userService.tempUsername = this.loginForm.get('username')?.value;
		}

		if (cognitoUser.challengeName == 'PASSWORD_VERIFIER' || cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
			this.currentState = LoginStates.CHANGE_PASSWORD;
			return;
		}

		if (this.userService.currentUser.mfaEnabled && !this.userService.currentUser.mfaRegistered) {
			this.openMfaDialog(true, 'APP', null);
			return;
		}

		if (this.redirectApp != 'id') {
			this.redirectUser();
			return;
		}

		this.userService.jwtUser = new JwtUser().deserialize(localStorage.getItem('jwtToken'));

		this.validateCurrentUser();
	}

	private showUserOrPasswordInvalid(){
		this.toastService.showDanger('Usuário ou senha inválidos.');
	}

	private redirectUser(): void {
		let url: string = this.redirectApp +
			'/login?token=' + this.userService.jwtToken +
			'&refreshToken=' + this.userService.refreshToken +
			'&deviceKey=' + this.userService.deviceKey +
			'&webClientId=' + environment.webClientId +
			'&returnUrl=' + this.redirectUrl ?? '';

		window.location.href = url;
	}

	private addPermissions(): void {
		let trainingPermission = false;

		if (this.userService.currentUser.roles) {
			this.userService.currentUser.roles.some(role => {
				role.permissions.some((permission: any) => {
					if (permission.name == IdPermissions.TRAINING_ACCESS) {
						trainingPermission = true;
						this.organizationService.currentOrganization = role.organization;
					}

					return trainingPermission;
				});

				return trainingPermission;
			});
		}

		if (trainingPermission) {
			this.ngxPermissionsService.addPermission(IdPermissions.TRAINING_ACCESS);
		}

		this.setAdminPermission();
	}

	private setAdminPermission() {
		if (this.userService.currentUser.organizations.find(org => {
			if (!this.organizationService.currentOrganization.id) {
				this.organizationService.currentOrganization = org;
			}

			return org.admin;
		})) {
			this.ngxPermissionsService.addPermission(IdPermissions.ALL_PERMISSIONS);
		}
	}


}

export enum LoginStates {
	LOGIN = 'login',
	CHANGE_PASSWORD = 'changePassword'
}
