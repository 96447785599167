import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastComponent } from './toast/toast.component';
import { LoginPageWrapperComponent } from './login-page-wrapper/login-page-wrapper.component';
import { HeaderComponent } from './header/header.component';
import { EvoSwitchComponent } from './evo-switch/evo-switch.component';
import { AvatarInitialsComponent } from './avatar-initials/avatar-initials.component';
import { InlineEditInputComponent } from './inline-edit-input/inline-edit-input.component';
import { DateFnsModule } from 'ngx-date-fns';
import { TranslateModule } from '@ngx-translate/core';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { TermsDialogComponent } from './terms-dialog/terms-dialog.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		NgbModule,
		NgSelectModule,
		DateFnsModule.forRoot(),
		NgxPermissionsModule.forChild(),
		TranslateModule,
	],
	declarations: [
		ToastComponent,
		LoginPageWrapperComponent,
		HeaderComponent,
		EvoSwitchComponent,
		AvatarInitialsComponent,
		InlineEditInputComponent,
		HelpDialogComponent,
		TermsDialogComponent,
	],
	providers: [],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		NgbModule,
		NgSelectModule,
		DateFnsModule,
		NgxPermissionsModule,
		TranslateModule,

		ToastComponent,
		LoginPageWrapperComponent,
		HeaderComponent,
		EvoSwitchComponent,
		AvatarInitialsComponent,
		InlineEditInputComponent,
		HelpDialogComponent,
		TermsDialogComponent,
	]
})

export class SharedModule {
}
