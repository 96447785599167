import { EvoModel } from './evo-model';
import { Organization } from './organization';

export class User extends EvoModel {

	public id: string;
	public name: string;
	public username: string;
	public email: string;
	public type: string;
	public status: any;
	public organizations: Organization[];
	public roles: any[];
	public createdAt: any;
	public updatedAt: any;
	public mfaEnabled: boolean;
	public mfaRegistered: boolean;
	public microsoftOnly: boolean;

	public isAdmin: boolean;

	public deserialize(input: any): this {
		return super.deserialize(input);
	}
}
