import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/service';
import { TranslateService } from '@ngx-translate/core';
import { Amplify } from '@aws-amplify/core';
import awsconfig from '../aws-exports';
import { Auth } from '@aws-amplify/auth';
import { environment } from 'environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

	constructor(public userService: UserService, translate: TranslateService) {
		translate.addLangs(['pt-br']);
		translate.setDefaultLang('pt-br');
		translate.use('pt-br');
	}

	ngOnInit() {
		Auth.configure({
			oauth: {
				domain: environment.apps.cognito,
				scope: ['email', 'openid', 'profile'],
				redirectSignIn: environment.apps.id + '/auth/callback',
				redirectSignOut:  environment.apps.id + '/logout',
				responseType: 'code'
			}
		});
	}

}
