import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { LogoutComponent } from './views/logout/logout.component';
import { SignUpComponent } from './views/sign-up/sign-up.component';
import { VerifyUserComponent } from './views/verify-user/verify-user.component';
import { AuthCallbackComponent } from './views/auth-callback/auth-callback.component';
import { AuthGuard } from "./service/auth-guard.service";

const routes: Routes = [
	{
		path: '',
		component: LoginComponent
	},
	{
		path: 'auth/callback',
		component: AuthCallbackComponent
	},
	{
		path: 'signup',
		component: SignUpComponent
	},
	{
		path: 'verify',
		component: VerifyUserComponent
	},
	{
		path: '',
		loadChildren: () => import('./views/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
	},
	{
		path: 'home',
		loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: 'activate',
		loadChildren: () => import('./views/activate-user/activate-user.module').then(m => m.ActivateUserModule)
	},
	{
		path: 'profile',
		loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: 'logout',
		component: LogoutComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
