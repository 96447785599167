import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseServiceClass } from './base-service';
import { HelpDeskMessage } from '@app/model';


@Injectable({providedIn: 'root'})
export class HelpDeskService extends BaseServiceClass<HelpDeskMessage> {
	constructor(protected httpService: HttpClient) {
		super(httpService, '/api/v1/notifications');
	}

	public sendMessage(helpMessage: HelpDeskMessage): Observable<any> {
		return this.httpService.post(`${this.entity_url}/helpdesk`, helpMessage);
	}
}
