<div class="inline-edit">
	<span class="text-center" [ngClass]="{'text-center': isCenter, 'empty' : !value }" *ngIf="!showInput" (click)="onShowInput()">{{ value ? value : placeholder }} <i class="fas fa-pencil-alt"></i></span>

	<div class="d-flex flex-column" style="width: 100%" *ngIf="showInput">
		<input class="form-control" [ngClass]="{'text-center': isCenter}" name="name" [(ngModel)]="tempValue" (keyup.enter)="onChangeDone()" (keydown)="preventSpace($event)" (keyup.escape)="onChangeCancel()"/>

		<div class="inline-edit-action">
			<button class="btn btn-edit mr-2" (click)="onChangeDone()"><i class="fas fa-check"></i></button>
			<button class="btn btn-edit" (click)="onChangeCancel()"><i class="fas fa-times"></i></button>
		</div>
	</div>
</div>
