import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService, UserService } from "@app/service";

import * as QRCode from 'qrcode';
import { Auth } from "@aws-amplify/auth";
import { JwtUser, SystemErrors } from "@app/model";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-mfa-modal-dialog',
	templateUrl: './mfa-dialog.component.html',
	styleUrls: ['./mfa-dialog.component.scss']
})
export class MfaDialogComponent implements OnInit, AfterViewInit {
	public mfaForm: FormGroup;
	public errorMessage: string;

	public otpAuthURL: string;
	public verificationCode: string;

	public mfaType: string = 'APP';
	public canRegister: boolean = false;

	public scratchCodes: number[] = [];

	public maxCodeSize: number = 6;
	public maxCodeSizeArr: number[];

	public mfaState: 'safeCodes' | 'register' = 'register';
	public registerAppDone: boolean = false;

	public qrCodeDataUrl: string = '';

	public challengeName: "SMS_MFA" | "SOFTWARE_TOKEN_MFA" | null | undefined;

	public loading: boolean = false;

	constructor(private userService: UserService,
				private modalService: NgbModal,
				private fb: FormBuilder,
				private activeModal: NgbActiveModal,
				private toastService: ToastService,
				private router: Router,
				private route: ActivatedRoute,
				@Inject(DOCUMENT) private document: Document) {
		this.mfaForm = this.fb.group({});

		for (let i = 1; i <= this.maxCodeSize; i++) {
			const controlName = `code${i}`;
			this.mfaForm.addControl(controlName, this.fb.control('', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]));
		}
	}

	ngOnInit(): void {
		this.maxCodeSizeArr = [];

		for(let i = 1; i <= this.maxCodeSize; i++) {
			this.maxCodeSizeArr.push(i);
		}
	}

	ngAfterViewInit() {
		if(this.canRegister) {
			this.generateQrCode();
		}
	}

	public generateQrCode() {
		const user = this.userService.cognitoUser;
		Auth.setupTOTP(user).then(
			code => {
				let url = `otpauth://totp/${this.document.location.host}?secret=${code}&issuer=Evollo`;

				QRCode.toDataURL(url).then(
					data => {
						this.otpAuthURL = data;
					});
			}).catch( error => {
				console.error('Error aqui:', error);
			});
	}

	onInputChange(event: any, index: any) {
		const newValue = event.target.value.replace(/[^0-9]/g, '');
		event.target.value = newValue;

		if (event.inputType === 'insertText' && event.data && event.data.length === 1 && event.data.match(/[0-9]/)) {
			const nextInput = document.getElementById(`code${index + 1}`);
			if (nextInput) {
				nextInput.focus();
			} else if (index === this.maxCodeSize) {
				this.submitMfaCode();
			}
		} else if (event.inputType === 'deleteContentBackward' && event.target.value === '') {
			const previousInput = document.getElementById(`code${index - 1}`);
			if (previousInput) {
				previousInput.focus();
			}
		}
	}

	pasteCode(event: any) {
		event.preventDefault();
		const pastedData = event.clipboardData.getData('text');
		if (pastedData.length === this.maxCodeSize && new RegExp(`^\\d{${this.maxCodeSize}}$`).test(pastedData)) {
			for (let i = 1; i <= this.maxCodeSize; i++) {
				(this.mfaForm.get(`code${i}`) as AbstractControl).setValue(pastedData[i - 1]);
			}
			const lastInput = document.getElementById(`code${this.maxCodeSize}`);
			if (lastInput) {
				lastInput.focus();
			}
			this.errorMessage = '';
			this.submitMfaCode();
		} else {
			this.errorMessage = 'Por favor, cole um código de 6 dígitos.';
		}
	}

	submitMfaCode() {
		if (this.mfaForm.valid) {
			this.verificationCode = '';
			for (let i = 1; i <= this.maxCodeSize; i++) {
				this.verificationCode += this.mfaForm.value[`code${i}`];
			}

			try {
				this.mfaForm.disable();
				this.loading = true;
				if(this.mfaType == 'APP' && this.canRegister) {
					this.verifyAppMfa();
					return;
				}

				Auth.confirmSignIn(this.userService.cognitoUser, this.verificationCode, this.challengeName).then(
					data => {
						Auth.currentAuthenticatedUser().then(
							currentAuthenticatedUser => {
								this.userService.updateTokens(currentAuthenticatedUser);
								this.userService.jwtUser = new JwtUser().deserialize(currentAuthenticatedUser.signInUserSession.accessToken.payload);

								this.userService.getCurrentUser().subscribe(
									currentUser => {
										this.userService.currentUser = currentUser;
										this.activeModal.close(true);
										this.loading = false;
									}, error => {
										this.toastService.showDanger(SystemErrors.UNEXPECTED_ERROR);
										console.error(error);
									});
							});
					}, error => {
						console.error('Error:', error);
						this.toastService.showDanger('Código inválido, tente novamente.');
						this.loading = false;
						this.mfaForm.enable();

						for (let i = 1; i <= this.maxCodeSize; i++) {
							(this.mfaForm.get(`code${i}`) as AbstractControl).setValue('');
						}
					});

			} catch (error) {
				this.toastService.showDanger(SystemErrors.UNEXPECTED_ERROR);
			}
		}
	}

	public dismissModal(): void {
		this.activeModal.dismiss();
	}

	public verifyAppMfa(): void {
		try {
			const user = this.userService.cognitoUser;
			Auth.verifyTotpToken(user, this.verificationCode).then(
				data => {
					Auth.setPreferredMFA(user, 'TOTP').then(
						mfaString => {

							if(mfaString != 'SUCCESS') {
								this.toastService.showDanger('Error ao registrar o MFA.');
								this.loading = false;
								this.closeModal();
								return;
							}

							Auth.currentAuthenticatedUser().then(
								user => {
									this.userService.updateTokens(user);
									this.userService.jwtUser = new JwtUser().deserialize(user.signInUserSession.accessToken.payload);

									this.userService.enableMfa().subscribe(
										mfaResult => {
											this.activeModal.close(true);
										},
										mfaError => {
											console.error(mfaError);
											this.toastService.showDanger('Não foi possível completar a configuração do MFA.')
										}
									);
								});

							return;
						});
				}, error => {
					console.error('Error:', error);
					this.toastService.showDanger('Código inválido, tente novamente.');
					this.loading = false;
					this.mfaForm.enable();

					for (let i = 1; i <= this.maxCodeSize; i++) {
						(this.mfaForm.get(`code${i}`) as AbstractControl).setValue('');
					}
				});
		} catch (error) {
			this.toastService.showDanger(SystemErrors.UNEXPECTED_ERROR);
		}
	}

	public closeModal() {
		this.activeModal.close();
	}
}
