import { Component } from '@angular/core';
import { UserService } from '@app/service';
import { ActivatedRoute } from '@angular/router';


@Component({
	selector: 'app-logout',
	template: ``
})
export class LogoutComponent {

	constructor(public userService: UserService,
	            private activatedRoute: ActivatedRoute) {
		this.userService.logout(this.activatedRoute.snapshot.queryParams['redirectApp']);
	}
}
