import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { Amplify } from '@aws-amplify/core';
import awsmobile from './aws-exports.js';
import { Auth } from '@aws-amplify/auth';

// Amplify.Logger.LOG_LEVEL = 'DEBUG';
Auth.configure(awsmobile);
Amplify.configure(awsmobile);

if (environment.production) {
  enableProdMode();
}

// Amplify.Logger.LOG_LEVEL = 'DEBUG';
Auth.configure(awsmobile);
Amplify.configure(awsmobile);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
