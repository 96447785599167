<div class="login-page-wrapper">
    <section class="login-section">
        <div class="login-form-wrapper">
            <ng-content select="[slot=form]"></ng-content>
        </div>
    </section>
    <section class="welcome-section --evollo">
		<div class="welcome-text">
			<div class="text-title">Percepções para ir além</div>
			<div class="text-subtitle">Um olhar verdadeiro para a interação entre marcas e consumidores.</div>
		</div>
		<div>
			<img src="assets/images/login-evollo-shapes.png" alt="login-shapes" style="width: 80%;">
		</div>
	</section>
</div>
