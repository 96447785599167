<div class="modal-header">
	<h4 class="modal-title">Verificação em 2 etapas</h4>
</div>

<div class="modal-body" *ngIf="mfaState == 'register'">

	<div class="text-center my-4" *ngIf="!mfaType">
		<p>Escolha qual método você quer utilizar.</p>
	</div>

	<header class="evo-tab-group mb-3" *ngIf="!canRegister">
		<span>Abra o autenticador e informe o código:</span>
	</header>

	<div *ngIf="mfaType == 'APP'">
		<div class="my-3" *ngIf="canRegister">
			<p class="instruction-text">
				<b>PASSO UM</b><br>
				Baixe/Use qualquer aplicativo autenticador (Google Authenticator, Microsoft Authenticator, etc.) em seu dispositivo móvel.
			</p>
		</div>
		<div class="my-3"  *ngIf="canRegister">
			<p class="instruction-text">
				<b>PASSO DOIS</b><br>
				No seu aplicativo autenticador, toque no ícone "+" e depois selecione "Scan QR code" para escanear o código QR abaixo.
			</p>
			<div class="text-center" *ngIf="otpAuthURL && canRegister">
				<img [src]="otpAuthURL" alt="Escaneie esse código utilizando seu App" />
			</div>
		</div>
		<div class="my-3" *ngIf="canRegister">
			<p class="instruction-text">
				<b>PASSO TRÊS</b><br>
				Depois que este código for escaneado, insira abaixo o código de 6 dígitos gerado pelo aplicativo.
			</p>
		</div>
	</div>

	<form class="my-4" [formGroup]="mfaForm" autocomplete="off">
		<div class="form-row">
			<ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6]">
				<div class="col">
					<input type="text" class="form-control text-center" [id]="'code' + i" [formControlName]="'code' + i" maxlength="1" (input)="onInputChange($event, i)" (paste)="pasteCode($event)">
				</div>
			</ng-container>
		</div>

		<div *ngIf="errorMessage" class="error-message text-danger mt-2">
			{{ errorMessage }}
		</div>
	</form>

	<div *ngIf="!loading">
		Perdeu acesso ao App? Entre em contato com o suporte.
	</div>

	<div class="mt-3 d-flex justify-content-end align-items-center" *ngIf="loading">
		<span class="spinner-border spinner-border-sm mr-2"></span>
		<span class="text-muted">Verificando...</span>
	</div>
</div>

<div class="modal-body" *ngIf="mfaState == 'safeCodes'">
	<p class="text-center">Salve os códigos para recuperar a conta caso perca o acesso ao Aplicativo de autenticação.</p>

	<div class="row my-3">
		<div class="col-4" *ngFor="let code of scratchCodes">
			<div class="px-3 py-2 text-center">
				<b>{{ code }}</b>
			</div>
		</div>
	</div>
</div>

<div class="modal-footer" *ngIf="mfaState == 'safeCodes'">
	<button type="button" class="btn btn-secondary" (click)="closeModal()">Fechar</button>
</div>
