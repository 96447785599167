import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService, UserService } from '@app/service';
import { CommonErrors, SystemErrors } from '@app/model';
import { HelpDialogComponent } from '../../shared/help-dialog/help-dialog.component';
import { TermsDialogComponent } from '../../shared/terms-dialog/terms-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

	public signupForm: FormGroup = new FormGroup({
		name: new FormControl(null, [Validators.required]),
		lastName: new FormControl(null, [Validators.required]),
		email: new FormControl(null, [Validators.required, Validators.email]),
		password: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&|<>])[A-Za-z\d@$!%*?#&|<>]{8,}$/)]),
		confirmPassword: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&|<>])[A-Za-z\d@$!%*?#&|<>]{8,}$/)]),
		terms: new FormControl(null, [Validators.required])
	});

	get name() { return this.signupForm.get('name'); }
	get lastName() { return this.signupForm.get('lastName'); }
	get email() { return this.signupForm.get('email'); }
	get password() { return this.signupForm.get('password'); }
	get confirmPassword() { return this.signupForm.get('confirmPassword'); }
	get terms() { return this.signupForm.get('terms'); }

	public showPassword: boolean = false;
	public showConfirmPassword: boolean = false;

	public passwordButtonPositionClass: string = '';
	public passwordConfirmButtonPositionClass: string = '';

	public registerDone: boolean = false;

	public isLoading: boolean = false;

	constructor(private userService: UserService,
	            private toastService: ToastService,
	            public modalService: NgbModal,
	            private router: Router) {
	}

	ngOnInit(): void {
		this.signupForm?.setValidators([this.validateConfirmPasswordIsEqualToPassword, this.validateTerms]);
	}

	onSubmit() {
		if (this.signupForm.invalid) {
			return;
		}

		this.isLoading = true;

		const user = this.signupForm.value;
		user.name = user.name + ' ' + user.lastName;
		delete user.confirmPassword;
		delete user.terms;
		delete user.lastName;

		this.userService.signUp(user).subscribe(() => {
			this.isLoading = false;
			this.toastService.showSuccess('Usuário criado com sucesso!');
			this.registerDone = true;
			// this.router.navigate(['/']);
		}, error => {
			this.isLoading = false;

			if(error.error.code) {
				this.toastService.showDanger(CommonErrors[error.error.code]);
				return;
			}

			this.toastService.showDanger(SystemErrors.UNEXPECTED_ERROR);
		});
	}

	changeShowPasswordButtonPosition() {
		if (this.password?.untouched) {
			return;
		}
		this.passwordButtonPositionClass = this.password?.invalid ? 'mr-4' : '';
	}

	changeShowConfirmPasswordButtonPosition() {
		if (this.confirmPassword?.untouched) {
			return;
		}
		this.passwordConfirmButtonPositionClass = this.confirmPassword?.invalid ? 'mr-4' : '';
	}

	validateTerms(form: AbstractControl): ValidationErrors | null {
		let error = null;

		if(!form.get('terms')?.value) {
			error = { needAccept: true }
			form.get('terms')?.setErrors({ needAccept: true });
		}

		return error;
	}

	validateConfirmPasswordIsEqualToPassword(form: AbstractControl): ValidationErrors | null {
		const password = form.get('password')?.value;
		const confirmPassword = form.get('confirmPassword')?.value;

		let error;
		if (password === confirmPassword) {
			error = null;
		} else {
			error = {notSame: true};
			form.get('confirmPassword')?.setErrors({notSame: true});
		}
		return error;
	}

	openTerms(event: any): void {
		event.stopPropagation();

		const modalRef = this.modalService.open(TermsDialogComponent, { size: 'xl', centered: true, scrollable: true });

		modalRef.result.then( data => {
			if(data) {
				this.terms?.setValue(true);
			}
		});
	}

	public preventSpace(event: any) {
		if(event.keyCode === 32){
			event.preventDefault();
		}
	}

}
