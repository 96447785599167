import { BaseServiceClass } from './base-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtUser, UpdateUser, User, ForgotPassword, ActivateUser, Password } from '@app/model';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, switchMap, tap } from "rxjs/operators";
import { ChangePassword } from '../model/request/change-password';
import { ToastService } from "./toast.service";

const API_URL = environment.apiUrl;

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	public redirectApp: string;
	public redirectUrl: any;

	constructor() {
	}

	public setRedirectApp(redirectApp: string){
		this.redirectApp = redirectApp;
	}

	public setRedirectUrl(redirectUrl: string){
		this.redirectUrl = redirectUrl;
	}
}
