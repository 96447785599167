import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login.component';
import { LoginWithoutEmailComponent } from './views/login/login-without-email/login-without-email.component';
import { LogoutComponent } from './views/logout/logout.component';
import { AuthInterceptor } from './service/auth-interceptor.service';
import { SharedModule } from './shared';

import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { SignUpComponent } from './views/sign-up/sign-up.component';
import { VerifyUserComponent } from './views/verify-user/verify-user.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorInterceptor } from '@app/service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AuthCallbackComponent } from './views/auth-callback/auth-callback.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaV3Module } from "ng-recaptcha";
import { MfaDialogComponent } from "./views/login/mfa/mfa-dialog.component";

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		LoginWithoutEmailComponent,
		LogoutComponent,
		SignUpComponent,
		VerifyUserComponent,
		AuthCallbackComponent,
		MfaDialogComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		SharedModule,
		AmplifyUIAngularModule,
		NgxPermissionsModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		RecaptchaV3Module,
		RecaptchaModule,
		RecaptchaFormsModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
