<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Política De Privacidade e Tratamento Dos Dados</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
        (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body py-3" style="padding-top: 0px;">
	<h5>1. POLÍTICA DE PRIVACIDADE</h5>

	<p>A EVOLLO acredita que confiança e privacidade são condições fundamentais para
		respaldar o crescimento contínuo da Internet. Acreditamos também que a coleta, o
		uso e a transferência de informações realizados de forma eficiente ajudam a
		melhorar o desenvolvimento da Internet e do comércio eletrônico, sempre que tais
		informações forem tratadas de maneira correta e responsável.</p>

	<h5>2. INTRODUÇÃO</h5>

	<p>Este texto apresenta a política da EVOLLO de privacidade e tratamento de dados
		através do site www.EVOLLO.com.br, em à Lei Geral de Proteção de Dados
		Pessoais (LGPD) – LEI Nº 13.709, DE 14 DE AGOSTO DE 2018 e ao Marco Civil
		da Internet – LEI Nº 12.965, DE 23 DE ABRIL DE 2014, que estabelece princípios,
		garantias, direitos e deveres para o uso da Internet no Brasil.
		Descrevemos quais dados pessoais são coletados e qual a finalidade da coleta
		quando você visita ou interage com o site.
		Ao selecionar o checkbox da declaração da privacidade, você dá o seu aceite e
		consentimento para as informações aqui declaradas.
		Para melhor entendimento desta declaração de privacidade, sugere-se a verificação
		dos termos na seção Glossário.</p>

	<h5>3. QUAIS DADOS PESSOAIS SÃO COLETADOS PELO SITE</h5>

	<p>Quando você acessa o site, automaticamente são coletados alguns dados, como:
		qual o aparelho que você está utilizando, qual o navegador, seu endereço IP, horário
		de acesso, localização e outros dados que podem estar disponíveis em cookies
		presentes no seu dispositivo de acesso. Se você assim configurar em seu
		navegador, a gravação e leitura de cookies poderá ser desativada.</p>

	<ol type="a">
		<li>
			<b>Cookies (Rastreadores de navegação)</b>

			<p>Como e por que usamos Cookies? Usamos Cookies para melhorar o uso e a
				funcionalidade das páginas e serviços da EVOLLO e para entender melhor
				como nossos visitantes os usam, bem como as ferramentas e serviços ali
				oferecidos. Os Cookies nos ajudam a adaptar as páginas e serviços da
				Política De Privacidade e Tratamento Dos Dados 2
				EVOLLO às suas necessidades pessoais, facilitar cada vez mais o seu uso,
				receber feedback da satisfação e nos comunicarmos com você de outros locais
				na internet.</p>

		</li>

		<li>
			<b>Lista de cookies utilizados</b>

			<p><b>Cookies de sessão</b>:São cookies temporárias, pelo que não fica qualquer
				registo no disco rígido do utilizador. A informação obtida por estas cookies serve
				para analisar padrões de tráfego do site e identificar problemas o que nos
				permite melhorar o conteúdo do site a navegação</p>

			<p><b>Cookies analíticas</b>:São aquelas que nos permitem realizar a análise estatística
				de como os utilizadores usam o site, permitindo destacar produtos ou serviços
				que podem ser do interesse dos utilizadores e monitorizar o desempenho do
				site, conhecendo quais as páginas mais populares, ou para determinar a razão
				de algumas páginas estarem a receber mensagens de erro. Estas cookies são
				utilizadas apenas para efeitos de criação e análise estatística, sem nunca
				recolher informação de carácter pessoal
			</p>

			<p><b>Cookies de funcionalidade</b>:As cookies de funcionalidade permitem relembrar
				as preferências do utilizador relativamente à navegação nos Websites, não
				necessitando, assim, de o reconfigurar e personalizar cada vez que o visita</p>

			<p><b>Cookies publicitários</b>:São aquelas que nos permitem gerir de uma forma mais
				eficiente a oferta de espaços publicitários que existem no site, podendo analisar
				os seus hábitos de navegação e mostrar-lhe publicidade relacionada com o seu
				perfil de navegação. Estas registam as preferências do utilizador, assim não
				necessita de personalizar o site cada vez que o visita</p>
		</li>

		<li>
			<b>Outras tecnologias similares</b>

			<p>As páginas ou serviços da EVOLLO ou de seus parceiros podem também usar
				outras tecnologias de rastreamento, inclusive endereços IP, arquivos de registro
				e sinalizadores da web, que também nos ajudam a adaptar os sites da às suas
				necessidades pessoais.</p>
		</li>
	</ol>

	<p>Também coletamos informações sobre a origem de seu acesso (se foi a partir de
		algum mecanismo de busca, rede social, endereçamento direto, anúncios etc.), bem
		como as páginas do nosso site que você visita, para melhor identificação dos
		assuntos da EVOLLO que são do seu interesse.</p>

	<p>Caso você preencha algum formulário apresentado pelo site, outros dados poderão
		ser coletados como nome, cargo /departamento, nome da empresa, telefones, email, entre outros. Todos os dados fornecidos ficarão armazenados em nossa base
		Política De Privacidade e Tratamento Dos Dados 3
		de dados. Lembre-se você obrigatoriamente deve ser maior de 18 anos para
		interagir em nosso site.</p>

	<p>Dependendo do tipo de Titular (colaboradores, fornecedores, parceiros e clientes) e
		como este Titular interage com a EVOLLO são coletadas diversas categorias de
		informações, conforme abaixo:</p>

	<ul>
		<li><b>Informações pessoais de contato</b>:Qualquer informação fornecida com
			intenção de contato, como nome, endereço postal, e-mail, endereço comercial,
			detalhes de redes sociais e número de telefone</li>
		<li><b>Informações de login de conta</b>:Qualquer informação que seja necessária para
			dar acesso a perfil de conta específico para acesso aos nossos serviços.
			Exemplos incluem endereço de e-mail, nome de usuário, senha em formato
			irrecuperável e/ou pergunta e resposta de segurança, dentre outros para efetuar
			seu acesso a serviços fornecidos por nós
		</li>
		<li><b>Informações técnicas sobre o computador/dispositivo móvel</b>:Qualquer
			informação sobre o sistema de computador ou outro dispositivo que você utiliza
			para acessar uma de nossas páginas, serviços ou aplicativos, endereço IP
			utilizado para conectar seu computador ou dispositivo à internet, o tipo de
			sistema operacional e o tipo e a versão do navegador da web, dentre outras
			informações de navegação
		</li>
		<li><b>Informações financeiras e de pagamento</b>:Informações necessárias para
			atender a um pedido/contrato/faturamento/cobrança/remuneração/reembolsos.
			A EVOLLO garante que seu serviço de processamento de pagamentos garante
			que as informações financeiras e de pagamento estão em conformidade com as
			leis, normas e os padrões de segurança aplicáveis
		</li>
		<li><b>Dados Pessoais Sensíveis</b>:Sempre que for necessário coletar e tratar Dados
			Pessoais Sensíveis por qualquer motivo, será solicitado prévio e expresso
			consentimento (por exemplo, prevenção a Covid-19, biometria para acesso às
			instalações ou ponto, etc.). Se for necessário o tratamento de Dados Pessoais
			sensíveis para outras finalidades, estas têm base legal e a EVOLLO proverá
			prévia ciência ao Titular</li>
		<li><b>Dados Pessoais de Crianças e Adolescentes</b>:Sempre que for necessário
			coletar e tratar Dados Pessoais de crianças e menores de idade, será solicitado
			consentimento explícito aos pais ou tutores (exemplo plano de saúde e outros
			benefícios e ou obrigações)</li>
	</ul>

	<h5>4. DIREITOS DO TITULAR</h5>

	<p>Os Titulares dos Dados Pessoais possuem, frente à legislação, os direitos de:</p>

	<ul>
		<li>Confirmação da existência de tratamento de Dados Pessoais</li>
		<li>Confirmação de quais Dados Pessoais dos Titulares estão na EVOLLO</li>
		<li>Solicitação da correção de dados incompletos, inexatos ou desatualizados</li>
		<li>Exclusão de dados, quando este forem tratados com base no consentimento do
			Titular ou quando os dados forem desnecessários, excessivos ou tratados em
			desconformidade com a legislação aplicável</li>
		<li>Solicitação de informações sobre possível uso compartilhado de dados</li>
	</ul>

	<p>Por motivos de segurança, a solicitação para o atendimento destes direitos, podem
		ser solicitados dados ou informações adicionais para a confirmação da identidade e
		da autenticidade do Titular.</p>

	<p>O Titular pode entrar em contato com a empresa através do email: <a href="mailto:dpo@evollo.com.br">dpo@evollo.com.br</a>.</p>

	<h5>5. QUAL A FINALIDADE DA COLETA DOS SEUS DADOS PESSOAIS</h5>

	<p>Os seus dados pessoais coletados serão utilizados para identificar assuntos de seu
		interesse com a EVOLLO, bem como para nos comunicarmos com você,
		fornecendo informações relacionadas aos nossos produtos e serviços, ou para
		informar detalhes sobre as vagas de emprego que oferecemos.</p>

	<p>Os dados colhidos relacionados ao seu equipamento e acesso, destinam-se a
		melhorar a eficiência do site e avaliar a eficácia das campanhas publicitárias que
		eventualmente fazemos.</p>

	<p>Algumas das utilizações acima relatadas incorporam o conceito de Tratamento de
		Dados, que são operações que podem ser realizadas pela EVOLLO sobre os dados
		pessoais ou sobre o conjunto de dados pessoais, por meios automatizados ou não.</p>

	<p>O tratamento e a proteção dos dados fornecidos, atendendo a legislação em vigor,
		serão feitos pela EVOLLO, cujas informações para contato estão disponíveis em
		nosso site.</p>

	<p>Você pode solicitar que os dados fornecidos sejam atualizados, pedir
		esclarecimentos sobre a forma de seu armazenamento e utilização ou revogar a
		qualquer tempo o consentimento para seu uso, através de manifestação por escrito
		à EVOLLO.</p>

	<p><b>Endereço para correspondência:</b></p>

	<p>Av. Queiroz Filho, nº 1560, Torre Gaivota, 3º andar, Vila Hamburguesa
		Política De Privacidade e Tratamento Dos Dados 5
		São Paulo, SP, CEP. 05319-000</p>

	<p>Nosso encarregado da proteção de dados pode ser contatado a partir do endereço
		para correspondência ou através do e-mail: <a href="mailto:dpo@evollo.com.br">dpo@evollo.com.br</a>.</p>

	<p>Na tabela estão descritas as finalidades dos Dados Pessoais, e os diferentes tipos
		de Dados Pessoais que coletamos para cada uma delas. Note, por favor, que nem
		todos os usos abaixo serão relevantes para todos os indivíduos</p>

	<h6>Utilização de Dados</h6>

	<table class="table">
		<thead>
		<tr>
			<th>Para que usamos seus Dados Pessoais</th>
			<th>Finalidade</th>
		</tr>
		</thead>
		<tbody>
		<tr>
			<td><i>Serviço ao consumidor/cliente</i>. Usamos Dados Pessoais para prestar os
				serviços ao consumidor/cliente, incluindo responder questionamentos e
				cumprir contratos. Requer o uso de algumas informações pessoais de
				contato e informações sobre o motivo do questionamento (por exemplo
				situação do pedido, problema técnico, questão ou reclamação sobre
				produto ou serviço e questionamento)</td>
			<td>Crumprir obrigações contratuais e obrigações legais; Melhorar e desenvolver novos produtos e serviços; Ser mais eficientes</td>
		</tr>
		<tr>
			<td><i>Recrutamento, seleção e admissão</i>. Tratamento dos processos de
				recrutamento e seleção de candidato. Para os não selecionados, seus
				dados permanecem em nossos bancos de talentos para futuros
				processos de seleção. No ato da admissão, podem ser coletados mais
				Dados Pessoais e alguns sensíveis (biometria para acesso às
				instalações e ponto, dados para plano de saúde, informações de
				dependentes para benefícios).</td>
			<td>Realizar a seleção de candidatos; Realizar a admissão do candidato; Cumprir obrigações legais</td>
		</tr>
		</tbody>
	</table>

	<h5>6. TRATAMENTO DOS DADOS PESSOAIS</h5>

	<p><b>A EVOLLO poderá realizar o tratamento dos Dados Pessoais coletados para:</b></p>

	<ul>
		<li>Execução de projetos e atividades de responsabilidade ambiental e social</li>
		<li>Realização de processos de prevenção à fraude</li>
		<li>Execução de atividades de relacionamento e atendimento ao cliente</li>
		<li>Venda de produtos e/ou serviços</li>
		<li>Concessão de benefícios</li>
		<li>Processos de recrutamento e admissão e treinamento, para cumprir com
			nossas obrigações trabalhistas com profissionais/ colaboradores</li>
		<li>Política De Privacidade e Tratamento Dos Dados 6</li>
		<li>Execução das atividades contratuais com clientes e prestadores de serviço
			incluindo obrigações financeiras, relacionadas aos produtos e/ou serviços
			adquiridos</li>
		<li>Cumprimento de obrigações legais e regulatórias</li>
		<li>Atender às solicitações dos clientes, ex-clientes, leads/ prospects</li>
		<li>Aprimorar os produtos e serviços ofertados</li>
		<li>Atender às determinações de autoridades competentes</li>
		<li>Processos jurídicos quando requeridos</li>
		<li>Notificação sobre a situação (status) e eventuais alterações em nossos produtos
			e serviços</li>
		<li>Realizar operações internas (financeiras, contábeis, trabalhistas, entre outras),
			solução de problemas, análise de dados, integração e consolidação de dados</li>
		<li>Gerenciar riscos e detectar, prevenir e/ou remediar fraudes ou outras atividades
			potencialmente ilegais ou proibidas, além de violações de políticas, contratos ou
			termos de uso aplicáveis</li>
		<li>Cumprir com obrigações legais ou regulatórias, ou conforme exigido em um
			processo judicial, por qualquer órgão de aplicação da lei ou do governo</li>
	</ul>

	<p><b>O término do tratamento de Dados Pessoais ocorrerá:</b></p>

	<ul>
		<li>Quando a finalidade para qual o dado pessoal foi coletado for alcançada ou os
			Dados Pessoais coletados deixarem de ser necessário</li>
		<li>Quando o Titular solicitar a exclusão de seus dados respeitando a legislação
			vigente</li>
		<li>Quando houver uma determinação legal neste sentido</li>
	</ul>

	<p><b>O armazenamento dos dados e informações será:</b></p>

	<ul>
		<li>Pelo tempo exigido por lei e/ou cumprimento de obrigação legal ou regulatória</li>
		<li>Até o término do tratamento de Dados Pessoais, conforme acima</li>
		<li>Respeitando as diretrizes da Política de Segurança da Informação.</li>
	</ul>

	<p><b>A eliminação dos dados e informações, quando necessário, será:</b></p>

	<ul>
		<li>Feita respeitando demais legislações existentes</li>
		<li>Feita de forma a eliminar todos as evidências e cópias existentes</li>
		<li>Executada com procedimentos estabelecidos de eliminação física ou eletrônica</li>
	</ul>

	<h5>7. COMPARTILHAMENTO DOS DADOS</h5>

	<p>O compartilhamento de dados e informações pode ocorrer para:</p>

	<p>Os prestadores de serviço, para atuarem na operação e execução dos serviços
		contratados, os quais estarão cientes e irão possuir as responsabilidades e
		comprometimento sobre a privacidade de Dados Pessoais acordados em cláusulas
		contratuais específicas</p>

	<ul>
		<li>As unidades bancárias, exclusivamente para realização de transações
			contratuais ou trabalhistas</li>
		<li>Os órgãos reguladores, autoridades judiciais ou administrativa, onde podemos
			compartilhar as informações pessoais para prestar às autoridades competentes
			todas as informações que forem solicitadas com relação ao Titular para
			investigação de suspeitas de violação da lei, ou para combater qualquer outra
			suspeita de descumprimento das nossas políticas e contratos</li>
	</ul>

	<p>Para os casos não previstos acima em que seja necessário o compartilhamento dos
		Dados Pessoais, será solicitada ao Titular dos Dados Pessoais a autorização
		expressa (consentimento), através do envio de uma notificação com informações
		sobre o compartilhamento.</p>

	<h5>8. COMPARTILHAMENTO DOS DADOS</h5>

	<p>As informações cadastrais fornecidas por você no Site, serão mantidas
		armazenadas por tempo indeterminado ou até que você solicite a exclusão delas.</p>

	<p>Mesmo mediante a sua solicitação de eliminação, a EVOLLO poderá conservar
		alguns dos seus dados nas seguintes situações: cumprimento de obrigação legal ou
		regulatória; estudo por órgão de pesquisa, transferência a terceiro e uso pela
		EVOLLO, com anonimização dos dados pessoais, sempre que possível.</p>

	<p>Poderemos atualizar esta política de privacidade de tempos em tempos, seja para
		melhor adaptação às condutas da EVOLLO, seja para atendimento às leis e
		regulamentos.</p>

	<h5>9. SITES DE TERCEIROS</h5>

	<p>O site da EVOLLO pode incluir links para sites de terceiros. A EVOLLO não
		representa nem garante a segurança em relação a esses sites. Você deve estar
		ciente de que os proprietários e operadores dos sites de terceiros podem coletar,
		usar ou transferir dados pessoais sob termos e condições diferentes dos da
		Política De Privacidade e Tratamento Dos Dados 8
		EVOLLO. Ao acessar o link para um site de terceiros, você deve se informar sobre
		as políticas de privacidade correspondentes.</p>

	<h5>10. COVID-19 – ITEM APLICÁVEL A COLABORADORES E VISITANTES</h5>

	<p>Em virtude das medidas de prevenção e controle de contágio de Covid-19, a
		EVOLLO e/ou condomínio poderão também coletar informações pessoais de seus
		colaboradores, prestadores de serviços e visitantes como:</p>

	<ul>
		<li>Status e histórico de saúde em relação ao Covid-19</li>
		<li>Informações sobre o local de trabalho</li>
		<li>Temperatura corporal</li>
	</ul>

	<p>Esta coleta dos dados tem a finalidade de auxiliar a reduzir o risco de contágio de
		Covid-19 nas dependências da EVOLLO e do condomínio.</p>

	<p>A medição térmica mede a temperatura de forma anônima e não retém esta
		informação.  Se a temperatura for igual ou superior a 37,7 graus Celsius, sua
		entrada será negada e/ou será solicitada a sua saída das instalações.</p>

	<p>Conforme a legislação, usos de máscara são obrigatórias e sua falta impede o
		acesso à EVOLLO e ao condomínio.</p>

	<h5>11. PRÁTICAS QUE TODOS DEVEM SEGUIR</h5>

	<p>Todos têm papel importante na proteção de seus Dados Pessoais. Ao criar uma
		conta online, uma senha assegurar-se de definir uma senha que seja de difícil de
		ser descoberta e nunca revelar a senha a outras pessoas. Cada um é responsável
		por manter a senha confidencial e intransferível.</p>

	<p>Ao usar computador compartilhado ou público, nunca escolher a opção de lembrar
		ID de login, endereço de e-mail, login ou senha e sempre certificar-se que saiu da
		sua conta (“log out”). Utilizar todas as configurações de privacidade ou controles
		que sejam fornecidos em nosso site, serviços ou aplicativos.</p>

	<h5>12. ALTERAÇÕES A ESTA DECLARAÇÃO</h5>

	<p>Sempre que a EVOLLO alterar formas e objetivos na coleta e tratamento de Dados
		Pessoais, esta declaração será atualizada.</p>

	<p>A EVOLLO se reserva o direito de fazer alterações às práticas e a esta Declaração
		de Privacidade a qualquer tempo.</p>

	<p>Solicita-se que esta declaração seja periodicamente acessada.</p>

	<h5>13. CONTATO</h5>

	<p>Para fazer perguntas sobre as nossas práticas de privacidade ou para fazer uma
		solicitação, por favor, entre em contato pelo e-mail: <a href="mailto:dpo@evollo.com.br">dpo@evollo.com.br</a>.</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">Aceitar política e termos de uso</button>
</div>
